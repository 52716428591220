"use client";

import useAppStore from "./app-store";
import { mergeCartAfterLogged } from "@/actions/ecom-cart-v2";
import { getClientId } from "@/lib/local/laka-local-storage";
import { getCurrentUser } from "@/lib/session";
import { useEffect } from "react";

const AppProvider = ({ children }) => {
  const { setUser, user } = useAppStore();
  useEffect(() => {
    getCurrentUser().then(async (user) => {
      if (user) {
        await mergeCartAfterLogged(getClientId());
      }
      setUser(user || null);
    });
  }, [children]);
  if (user === undefined) return <></>;
  return children;
};

export default AppProvider;
