"use client";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Icons } from "../icons";
import PopUp from "../ui/popup";
import usePhotoPreview from "./usePhotoPreviewHook";
import useHorizontalScroll from "@/hooks/use-horizontal-scroll";
import ReactPlayer from "react-player";

interface PhotoPreviewProps {}
const PhotoPreview: React.FC<PhotoPreviewProps> = () => {
  const {
    isOpen,
    onClose,
    onChangeSlider,
    index: position,
    images,
    activeTab,
    setActiveTab,
    tabs,
  } = usePhotoPreview();

  const floatBar = useHorizontalScroll();

  const onChangeTab = (id) => {
    setActiveTab(id);
    onChangeSlider(0);
  };

  const handleClose = () => {
    onClose();
    setActiveTab("-1");
  };

  const handleSlideChange = (index) => {
    onChangeSlider(index);
  };
  if (!isOpen) return <></>;

  let photoList = images;
  if (activeTab !== "-1")
    photoList = tabs.find((tab) => tab.key === activeTab)?.images || [];

  const renderTabs = () => {
    const defaultTabs = [{ key: "-1", name: "Tất cả", images: images }];
    const tabList = tabs.length === 1 ? tabs : [...defaultTabs, ...tabs];
    return (
      <div className="no-scrollbar flex w-full flex-row gap-2 overflow-x-auto whitespace-nowrap px-2 ">
        {tabList.map((item, index) => (
          <button
            onClick={() => onChangeTab(item.key)}
            key={item.key}
            className={`flex items-center rounded-full ${
              activeTab === item.key ? "bg-slate-100 text-black" : "text-white"
            } border border-gray-400 px-4 py-2`}
          >
            {item.name}
          </button>
        ))}
      </div>
    );
  };

  const customCarouselStyles = `
    .carousel .thumb {
      border: none !important;
      padding: 0!important;
      margin-right: 10px!important;
    }
    .carousel .carousel-status {
      display: none !important;
    }
  `;

  const body = (
    <div className="flex h-dvh w-full flex-col gap-3 py-5">
      <div className="inline-flex w-full items-center justify-between px-2">
        <div className="flex w-full items-center justify-end ">
          <div className="flex-1 pl-6 text-center text-base font-semibold leading-none text-white">
            {position + 1} / {photoList.length}
          </div>
          <div
            className="flex size-6 items-center justify-center"
            onClick={handleClose}
          >
            <Icons.close className=" size-6 cursor-pointer" color="white" />
          </div>
        </div>
      </div>

      {!!tabs.length && renderTabs()}

      <div className="relative flex size-full grow items-center">
        <style>{customCarouselStyles}</style>
        <div className="flex h-full items-center">
          <Carousel
            onChange={handleSlideChange}
            showThumbs={false}
            showStatus={true}
            showArrows={true}
            showIndicators={false}
            infiniteLoop
            selectedItem={position}
            renderArrowPrev={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`${
                    hasPrev ? "absolute" : "hidden"
                  } inset-y-0 left-0 z-20 my-20 flex cursor-pointer items-center justify-center pl-3`}
                  onClick={clickHandler}
                >
                  <div className="flex size-8 items-center justify-center rounded-lg bg-white opacity-80">
                    <Icons.chevronLeft className="size-6 text-secondary-100" />
                  </div>
                </div>
              );
            }}
            renderArrowNext={(clickHandler, hasNext) => {
              return (
                <div
                  className={`${
                    hasNext ? "absolute" : "hidden"
                  } inset-y-0 right-0 z-20 my-20 flex cursor-pointer items-center justify-center pr-3`}
                  onClick={clickHandler}
                >
                  <div className="flex size-8 items-center justify-center rounded-lg bg-white opacity-80">
                    <Icons.chevronRight className="size-6 text-secondary-100" />
                  </div>
                </div>
              );
            }}
          >
            {photoList.map((item, index) => (
              <div
                key={index}
                className="size-full max-h-[70vh] overflow-hidden"
              >
                {item.url.endsWith(".mp4") ? (
                  // <video className="size-full object-contain" controls>
                  //   <source src={item.url} type="video/mp4" />
                  // </video>
                  <ReactPlayer
                    className="size-full object-contain"
                    url={item.url}
                    width="100%"
                    height="100%"
                    controls={true}
                  />
                ) : (
                  <img
                    className="size-full object-contain"
                    alt={item.alt}
                    src={item.url}
                  />
                )}
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <div
        ref={floatBar.containerRef}
        className=" hidden w-full items-center md:inline-flex"
      >
        <div
          onClick={floatBar.scrollLeft}
          className={`flex cursor-pointer items-center justify-center px-3 ${
            floatBar.isStart ? "opacity-30" : ""
          }`}
        >
          <div className="flex size-8 items-center justify-center rounded-lg bg-white opacity-80">
            <Icons.chevronLeft className="size-6 text-secondary-100" />
          </div>
        </div>
        <div
          ref={floatBar.scrollRef}
          className="no-scrollbar flex w-full gap-2 overflow-x-auto px-2"
        >
          {photoList.map((item, index) => (
            <div
              key={index}
              className={`max-h-20 min-h-20 min-w-20 max-w-20 cursor-pointer overflow-hidden rounded-xl ${
                position === index
                  ? "border-3 border-white"
                  : "border-none opacity-50"
              }`}
              onClick={() => handleSlideChange(index)}
            >
              {item.url.endsWith(".mp4") ? (
                // <video className="size-full object-cover" controls={false}>
                //   <source src={item.url} type="video/mp4" />
                // </video>
                <ReactPlayer
                  className="size-full object-contain"
                  url={item.url}
                  width="100%"
                  height="100%"
                  controls={false}
                />
              ) : (
                <img
                  className="size-full object-cover"
                  alt={item.alt}
                  src={item.url}
                />
              )}
            </div>
          ))}
        </div>
        <div
          onClick={floatBar.scrollRight}
          className={`flex cursor-pointer items-center justify-center pl-5 pr-3 ${
            floatBar.isEnd ? "opacity-30" : "opacity-100"
          }`}
        >
          <div className="flex size-8 items-center justify-center rounded-lg bg-white opacity-80">
            <Icons.chevronRight className="size-6 text-secondary-100" />
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <PopUp
      isOpen={isOpen}
      body={body}
      onClose={onClose}
      bgGradient={false}
      maxHeightBody=""
      maxWidth={"md:max-w-6xl"}
      backdrop="bg-[#000000e6]"
    />
  );
};

export default PhotoPreview;
