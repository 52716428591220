import { useEffect, useRef, useState, RefObject } from "react";

interface UseHorizontalScrollReturn {
  isEnd: boolean;
  isStart: boolean;
  scrollLeft: () => void;
  scrollRight: () => void;
  scrollRef: RefObject<HTMLDivElement>;
  containerRef: RefObject<HTMLDivElement>;
}

const useHorizontalScroll = (): UseHorizontalScrollReturn => {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [isStart, setIsStart] = useState<boolean>(true);
  const [render, setRender] = useState<boolean>(true);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
        setIsEnd(scrollLeft + clientWidth >= scrollWidth - 1);
        setIsStart(scrollLeft === 0);
      }
    };

    const scrollElement = scrollRef.current;
    scrollElement?.addEventListener("scroll", handleScroll);

    return () => {
      scrollElement?.removeEventListener("scroll", handleScroll);
    };
  }, [render]);

  const scroll = (distance: number) => {
    if (scrollRef.current && containerRef.current) {
      scrollRef.current.scrollBy({
        left: distance,
        behavior: "smooth",
      });
      setRender((pre) => !pre)
    }
  };

  const scrollLeft = () => scroll(-containerRef.current!.offsetWidth + 150);
  const scrollRight = () => scroll(containerRef.current!.offsetWidth - 150);

  return {
    isEnd,
    isStart,
    scrollLeft,
    scrollRight,
    scrollRef,
    containerRef,
  };
};

export default useHorizontalScroll;
